<template>
    <div class="layout-config h-100 p-10">
      <Row type="flex" :gutter="10">
        <Col span="4">
          <Input v-model="param.query" placeholder="请输入研学漫游名称" clearable />
        </Col>
        <Col span="4">
          <Button type="primary" @click="getData()">查询</Button>
          <Button type="success" class="m-l-5" to="/config/dataManage/roam/roamPage" v-if="isAuth">新增</Button>
        </Col>
      </Row>
      <Table border :columns="cols" :data="table.data && table.data.records" ref="table" class="m-t-10"></Table>
      <Page :page="param.current" @on-change="getData" :page-size="10" :total="table.total" show-total class="text-r m-t-10" />
    </div>
  </template>
  
  <script>
  import { getRoam, deleteRoam } from '@/libs/api/roam'
  import { util } from '@/libs'
import { mapState } from 'vuex'
  export default {
    data() {
      const _this = this
      return {
      isAuth: null,
        param: {
            current: 1,
            size: 10,
            order: 'name',
        },
        table: {},
        cols: [
          { title: "序号", type: "index", key: "index", width: 70, align: "center" },
          { title: "研学漫游名称", key: "name", width: 200 },
          { title: "研学漫游类型", key: "type", render(h, w) {
            let roam_type = '其他'
            switch (w.row.type) {
                case 'point':
                roam_type = '研学点'
                break
                case 'line':
                roam_type = '研学路线'
                break
                case 'panorama':
                roam_type = '研学全景'
                break
            }
            return h("span", roam_type)
          } },
          { title: "研学漫游内容", key: "content", render(h, w) {
            return h("span", w.row.content && w.row.content.join(","))
          } },
          {
            title: "操作", width: 200, render(h, w) {
              const btns = []
              if(w.row.content) {
                btns.push(h("Button", {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path: '/config/dataManage/roam/roamView',
                        query: { id: w.row.id }
                      })
                    }
                  }
                }, "预览"))
              }
              if(_this.isAuth) {
                btns.push(h("Button", {
                    props: { type: "success", size: "small" },
                    on: {
                      click() {
                        _this.$router.push({
                          path: '/config/dataManage/roam/roamPage',
                          query: { id: w.row.id }
                        })
                      }
                    }
                  }, "编辑"))
                  btns.push(h("Button", {
                    props: { type: "error", size: "small" },
                    on: {
                      click() {
                        _this.$Modal.confirm({
                          title: "删除",
                          content: `是否删除当前${w.row.name}?`,
                          onOk: () => {
                              deleteRoam({ id: w.row.id }).then((res) => {
                              if(res.code == "HA0200") {
                                _this.$Message.success("删除成功！");
                                _this.getData();
                              }
                            });
                          }
                        });
                      }
                    }
                  }, "删除"))
              }
              return btns
            }
          }
        ]
      }
    },
    computed: {
      ...mapState({
        userId: state => state.user.userId,
        roleCode: state => state.user.roleCode
      })
    },
    methods: {
      // 获取数据列表
      getData(page) {
        Object.assign(this.param, { current: page ? page : 1 })
        getRoam(this.param).then(res => {
            console.log(res)
            res.code == "HA0200" ? this.table = res : this.$Message.error(res.msg || "查询错误")
        });
      }
    },
    mounted() {
      this.isAuth = this.roleCode.includes('admin') || this.roleCode.includes('mechanismAdmin') || this.roleCode.includes('teach') || this.roleCode.includes('yxpt-t')
      this.getData()
    }
  }
  </script>
  
  <style lang="less" scoped></style>
  